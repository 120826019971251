<script>

import vueDropzone from "vue2-dropzone";
import {
	campaignMethods,
} from "@/state/helpers";


export default {
  components: {
    vueDropzone
  },
  data() {
    return {
      dropzoneOptions: {
        thumbnailWidth: 80,
        maxFilesize: 0.5,
        autoProcessQueue: false,
        url: "OnFilesAdded",
        addRemoveLinks: true,
        dictRemoveFile: this.$t('campaigns.new_campaign_product_customize_images_remove_picture'),
      }
    };
  },
  methods: {
    ...campaignMethods,
    onCustomizeProductClicked(){
      
      let files = this.$refs.myVueDropzone.getQueuedFiles();

      this.$refs.myVueDropzone.removeAllFiles();

      if(files  && files.length >0){
        let count=0;
        let loader = this.$loading.show();
        this.customFields.images=[];
        files.forEach(file => {
        
        this.uploadPicture(file)
          .then((res) => {
            this.customFields.images.push({'url' : res.data.url});
          })
					.catch(() => {
            this.$notify({ type: 'error', text: this.$t("campaigns.updateCampaignPicture_error"),title:  this.$t("campaigns.new_campaign") });
          })
          .finally(()=>{
            count++;
            if(count == files.length) {
              loader.hide();
              this.$emit('updateProduct',true);
            }
          })
        });
      }else{
        this.$emit('updateProduct',false);
      }
    },
  },
  mounted() {
  },
  props: {
    customFields: {
      type: Object,
    },
  },
  emits: ['updateProduct']
};
</script>

<template>
  <div>
    <div class="mb-3">
      <label >{{$t('campaigns.new_campaign_product_customize_images')}}</label>
      <vue-dropzone
        id="dropzone"
        ref="myVueDropzone"
        :use-custom-slot="true"
        :options="dropzoneOptions"
      >
        <div class="dropzone-custom-content">
          <i class="display-4 text-muted bx bxs-cloud-upload"></i>
          <h4> {{$t('campaigns.new_campaign_product_customize_images_upload')}}</h4>
        </div>
      </vue-dropzone>
      <p class="card-title-desc">
        * {{$t('campaigns.new_campaign_product_customize_images_obs')}}
      </p>
    </div>
    <div class="text-end pt-5 mt-3">
      <b-button variant="primary" class="ms-1" @click="onCustomizeProductClicked">{{$t('common.confirm')}}</b-button>
    </div>  
  </div>
</template>

<style>
  
 .dropzone .dz-preview .dz-details .dz-size {
    visibility: hidden;
  }
  .dropzone .dz-preview .dz-details .dz-filename {
    visibility: hidden;
  }
  .dropzone .dz-preview .dz-remove {
    color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: none;
    margin-left: unset;
    border: unset;
    font-size: 0.6rem;
  }

  .dropzone .dz-preview .dz-progress {
    visibility: hidden;
  }
</style>