<script>

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//import NumberInputSpinner from "vue-number-input-spinner";
import Multiselect from "vue-multiselect";

import {
  campaignMethods,
  commonMethods
} from "@/state/helpers";

/**
 * Campaign Settings component
 */
export default {
  components: {
    ckeditor: CKEditor.component,
    Multiselect
    //NumberInputSpinner
  },
  data() {
    return {
      welcomePicture : null,
      goodbyePicture : null,
      predefinedPicture :null,
      placeholderPicture:null,
      editor: ClassicEditor,
      sendEmailInvitation: true,
      editorData:null,
      accentColor: "#3a3a3a",
      user: null,
      useRate:true,
      useStockControl:false,
      useConfirmExit:true,
      useAdminTagsAndComments:true,
      useCart : true,
      useRedirectToDetail: true,
      checkoutUrl:'',
      noCameraLabel: null,
      checkoutMethod:null,
      checkoutMethods:[
        {
          name : this.$t("campaigns.edit_campaign_settings_checkout_methods_redirect"),
          id:"redirectWhenFinished"
        },
        {
          name : this.$t("campaigns.edit_campaign_settings_checkout_methods_iframe"),
          id:"iframe"
        },
        {
          name : this.$t("campaigns.edit_campaign_settings_checkout_methods_in_call"),
          id:"checkoutInCall"
        }
      ],
      redirectCheckoutUrl: null,
      redirectUrl:null,
      useAddToCartNotification: false
    };
  },
  watch: {
		
	},
  methods: {
    ...commonMethods,
    ...campaignMethods,

    updateCampaignSettings(){
      let body = {
        _id: this.campaign._id,
        settings: this.campaign.settings 
      }

      let loader = this.$loading.show();
      this.updateCampaign(body).then(()=>{
          this.$notify({ type: 'success', text: this.$t("campaigns.edit_campaign_success"),title:  this.$t("campaigns.edit_campaign") });

        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("campaigns.edit_campaign_error"),title:  this.$t("campaigns.edit_campaign") });
        }).finally(()=>{
          loader.hide();
        })
    },
    updateWelcomePicture() {
      var file = this.$refs.welcomePicture.files[0];
      this.$refs.welcomePicture.files = null;

      if(file){
        this.uploadMedia(file)
				.then((res) => {
            this.campaign.settings = this.campaign.settings || {};
            this.campaign.settings.lobby = this.campaign.settings.lobby || {}

            this.campaign.settings.lobby.welcomePicture = res.data.url;
            this.welcomePicture = this.campaign.settings.lobby.welcomePicture;

            this.updateCampaignSettings();

            return true;                  
					})
					.catch(() => {
            this.$notify({ type: 'error', text: this.$t("campaigns.updateCampaignPicture_error"),title:  this.$t("campaigns.new_campaign") });
					})
			}
    },
    removeWelcomePicture(){
      this.campaign.settings.lobby.welcomePicture = null;
      this.welcomePicture = null;

      this.updateCampaignSettings();

    },
    updateGoodbyePicture(){
      var file = this.$refs.goodbyePicture.files[0];
      this.$refs.goodbyePicture.files = null;

      if(file){
        this.uploadMedia(file)
				.then((res) => {

            this.campaign.settings = this.campaign.settings || {};
            this.campaign.settings.lobby = this.campaign.settings.lobby || {}
            
            this.campaign.settings.lobby.goodbyePicture = res.data.url;
            this.goodbyePicture = this.campaign.settings.lobby.goodbyePicture;

            this.updateCampaignSettings();

            return true;                  
					})
					.catch(() => {
            this.$notify({ type: 'error', text: this.$t("campaigns.updateCampaignPicture_error"),title:  this.$t("campaigns.new_campaign") });
					})
			}
    },
    removeGoodbyePicture(){
      this.campaign.settings.lobby.goodbyePicture = null;
      this.goodbyePicture = null;

      this.updateCampaignSettings();
    },

    updatePredefinedPicture(){
      var file = this.$refs.predefinedPicture.files[0];
      this.$refs.predefinedPicture.files = null;

      if(file){
        this.uploadMedia(file)
				.then((res) => {
            this.campaign.settings = this.campaign.settings || {};
            this.campaign.settings.videocall = this.campaign.settings.videocall || {}

            this.campaign.settings.videocall.predefinedPicture = res.data.url;
            this.predefinedPicture = this.campaign.settings.videocall.predefinedPicture;

            this.updateCampaignSettings();

            return true;                  
					})
					.catch(() => {
            this.$notify({ type: 'error', text: this.$t("campaigns.updateCampaignPicture_error"),title:  this.$t("campaigns.new_campaign") });
					})
			}

    },
    removePredefinedPicture(){
      this.campaign.settings.videocall.predefinedPicture = null;
      this.predefinedPicture = null;

      this.updateCampaignSettings();
    },

    onColorChanged(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.accentColor = this.accentColor;

      this.updateCampaignSettings();
    },

    onUseAddToCartNotificationChanged(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.useAddToCartNotification = this.useAddToCartNotification;

      this.updateCampaignSettings();
    },

    onSendInvitationByEmailChecked(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.invitation = this.campaign.settings.invitation || {}

      this.campaign.settings.invitation.sendEmailInvitation = this.sendEmailInvitation;

      this.updateCampaignSettings();
    },

    onUseRateChecked(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.useRate = this.useRate;

      this.updateCampaignSettings();
    },

    onUseAdminTagsAndCommentsChecked(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.useAdminTagsAndComments = this.useAdminTagsAndComments;

      this.updateCampaignSettings();
    },

    onuseConfirmExitChecked(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.useConfirmExit = this.useConfirmExit;

      this.updateCampaignSettings();
    },

    onUseStockControlChecked(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.useStockControl = this.useStockControl;

      this.updateCampaignSettings();
    },
    
    onUseCartChecked(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.useCart = this.useCart;

      this.updateCampaignSettings();
    },

    onUseRedirectToDetailChecked(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.useRedirectToDetail = this.useRedirectToDetail;

      this.updateCampaignSettings();
    },

    onNoCameraLabelClicked(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.noCameraLabel = this.noCameraLabel!= null && this.noCameraLabel!= '' ? this.noCameraLabel : null;

      this.updateCampaignSettings();
    },
    onCheckoutMethodSelected(){

      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      if(!this.checkoutMethod || this.checkoutMethod.id != 'redirectWhenFinished'){
        this.redirectCheckoutUrl = null;
        this.redirectUrl = null;
        this.campaign.settings.videocall.redirectCheckoutUrl = null;
        this.campaign.settings.videocall.redirectUrl = null;
      }
      
      this.campaign.settings.videocall.checkoutMethod = this.checkoutMethod ? this.checkoutMethod.id : null;

      this.updateCampaignSettings();
      
    },
    onRedirectCheckoutUrlChanged(){

      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.redirectCheckoutUrl = this.redirectCheckoutUrl ? this.redirectCheckoutUrl : null;

      this.updateCampaignSettings();

      this.submitted = false;
    },

    onRedirectUrlChanged(){

      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.videocall = this.campaign.settings.videocall || {}

      this.campaign.settings.videocall.redirectUrl = this.redirectUrl ? this.redirectUrl : null;

      this.updateCampaignSettings();

      this.submitted = false;
    },

    onSaveInvitationSettings(){
      this.campaign.settings = this.campaign.settings || {};
      this.campaign.settings.invitation = this.campaign.settings.invitation || {}

      this.updateCampaignSettings();

    }
  },
  mounted() {

    this.user = JSON.parse(localStorage.getItem("auth.currentUser")).user;
    
    //lobby 
    this.welcomePicture = this.campaign && this.campaign.settings && this.campaign.settings.lobby ? this.campaign.settings.lobby.welcomePicture : null;
    this.goodbyePicture = this.campaign && this.campaign.settings && this.campaign.settings.lobby ? this.campaign.settings.lobby.goodbyePicture : null;
    
    //videocall
    this.predefinedPicture = this.campaign && this.campaign.settings && this.campaign.settings.videocall ? this.campaign.settings.videocall.predefinedPicture : null;
    this.accentColor = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.accentColor ? this.campaign.settings.videocall.accentColor : "#3a3a3a";
    this.noCameraLabel = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.noCameraLabel ? this.campaign.settings.videocall.noCameraLabel : null;
    
    //general
    this.useRate = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.useRate ? this.campaign.settings.videocall.useRate : false;
    this.useAdminTagsAndComments = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.useAdminTagsAndComments ? this.campaign.settings.videocall.useAdminTagsAndComments : false;
    this.useConfirmExit = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.useConfirmExit ? this.campaign.settings.videocall.useConfirmExit : false;
    this.useStockControl = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.useStockControl ? this.campaign.settings.videocall.useStockControl : false;
    this.useCart = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.usecart ? this.campaign.settings.videocall.useCart : true;
    this.useRedirectToDetail = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.useRedirectToDetail ? this.campaign.settings.videocall.useRedirectToDetail : false;
    
    this.checkoutMethod = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.checkoutMethod ? this.checkoutMethods.filter(c=> c.id == this.campaign.settings.videocall.checkoutMethod)[0]  : null;
    this.redirectCheckoutUrl = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.redirectCheckoutUrl ? this.campaign.settings.videocall.redirectCheckoutUrl  : null;
    this.redirectUrl = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.redirectUrl ? this.campaign.settings.videocall.redirectUrl  : null;
    this.useAddToCartNotification = this.campaign && this.campaign.settings && this.campaign.settings.videocall && this.campaign.settings.videocall.useAddToCartNotification ? this.campaign.settings.videocall.useAddToCartNotification  : false;
    //invitation
    this.sendEmailInvitation = this.campaign && this.campaign.settings && this.campaign.settings.invitation ? this.campaign.settings.invitation.sendEmailInvitation : true;

    
  },
  props: {
    campaign: {
      type: Object,
    },
    invitation:{
        type:Object
      },      
  },
  
};
</script>

<template>
  <div>
    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="bx bx-cog"></i>
          </span>
          <span class="d-none d-sm-inline-block">  <i class="bx bx-cog"></i> General</span>
        </template>
        <div class="row pt-3">
          <div class="col-sm-6">
            <div class="mb-3">
              <b-form-checkbox
                    v-model="useCart"
                    class="mb-3"
                    checked
                    @change="onUseCartChecked"
                    plain>
                    {{$t("campaigns.edit_campaign_settings_campaign_useCart") }}  
              </b-form-checkbox>
            </div>
          </div>
        </div> 
        <div class="row pt-3" v-if="useCart">
          <div class="col-sm-6">
            <div class="mb-3">
              <b-form-checkbox
                    v-model="useRedirectToDetail"
                    class="mb-3"
                    checked
                    @change="onUseRedirectToDetailChecked"
                    plain>{{ $t("campaigns.edit_campaign_settings_campaign_redirectToDetail") }}</b-form-checkbox>
            </div>
          </div>
        </div> 
        <div class="row pt-3" v-if="useCart">
          <div class="col-sm-6">
            <div class="mb-3">
              <b-form-checkbox
                    v-model="useStockControl"
                    class="mb-3"
                    checked
                    @change="onUseStockControlChecked"
                    plain
                    >{{ $t("campaigns.edit_campaign_settings_videocall_useStockControl")}}</b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label for="role">{{$t('campaigns.edit_campaign_settings_checkout_methods')}}</label>
                <multiselect 
                  v-model="checkoutMethod"
                  :options="checkoutMethods"
                  :selectLabel="$t('common.select')"
                  :deselectLabel="$t('common.deselect')"
                  :placeholder="$t('campaigns.edit_campaign_settings_checkout_methods_placeholder')"
                  :multiple="false"
                  label="name"
                  track-by="id"
                  :showNoResults="false"
                  @input="onCheckoutMethodSelected">
                </multiselect>
              </div>
            </div>
          </div>   
          <div class="row" v-if="checkoutMethod?.id == 'redirectWhenFinished'">
          <div class="col-6">
            <div class="mb-3">
              <label for="role">{{ $t("campaigns.edit_campaign_settings_checkout_methods_redirect_checkoutUrl") }}</label>
              <b-input-group>
              <b-form-input
                v-model="redirectCheckoutUrl"
                type="text">
              </b-form-input>
              <b-input-group-append>
                  <b-button variant="primary" @click="onRedirectCheckoutUrlChanged"><i class="bx bx-save"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
           </div>
          </div>
          <div class="row" v-if="checkoutMethod?.id == 'redirectWhenFinished'">
          <div class="col-6">
            <div class="mb-3">
              <label for="role">{{ $t("campaigns.edit_campaign_settings_checkout_methods_redirect_url") }}</label>
              <b-input-group>
              <b-form-input
                v-model="redirectUrl"
                type="text">
              </b-form-input>
              <b-input-group-append>
                  <b-button variant="primary" @click="onRedirectUrlChanged"><i class="bx bx-save"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
           </div>
          </div>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="bx bx-home-circle"></i>
          </span>
          <span class="d-none d-sm-inline-block"><i class="bx bx-home-circle"></i> {{$t('campaigns.edit_campaign_settings_lobby')}}</span>
        </template>
        <div class="row pt-5">
          <div class="col-sm-6">
            <div class="mb-3">
              <label for="welcomePicture">{{$t('campaigns.edit_campaign_settings_lobby_WelcomeImageLabel')}}</label>
                <input
                  ref="welcomePicture"
                  @change="updateWelcomePicture"
                  accept="image/*"
                  class="form-control "
                  id="welcomeImage"
                  type="file"/>
            </div>
          </div>
          <div class="col-sm-6 flex" v-if="welcomePicture">
            <div class="avatar-xs me-3 edit-picture-button" v-on:click="removeWelcomePicture">
              <span class="avatar-title bg-light rounded-circle font-size-15 icon-color-grey">
                <i class="bx bxs-trash"></i>
              </span>
            </div>
            <img
              class="rounded me-2"
              height="120"
              :src="welcomePicture"
              data-holder-rendered="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="mb-3">
              <label for="goodbyePicture">{{$t('campaigns.edit_campaign_settings_lobby_GoodByeImageLabel')}}</label>
                <input
                  ref="goodbyePicture"
                  @change="updateGoodbyePicture"
                  accept="image/*"
                  class="form-control "
                  id="goodbyePicture"
                  type="file"/>
            </div>
          </div>
          <div class="col-sm-6 flex" v-if="goodbyePicture">
            <div class="avatar-xs me-3 edit-picture-button" v-on:click="removeGoodbyePicture">
              <span class="avatar-title bg-light rounded-circle font-size-15 icon-color-grey">
                <i class="bx bxs-trash"></i>
              </span>
            </div>
            <img
              class="rounded me-2"
              height="120"
              :src="goodbyePicture"
              data-holder-rendered="true"
            />
          </div>
        </div> 
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="bx bx-video-recording"/>
          </span>
          <span class="d-none d-sm-inline-block"> <i class="bx bx-video-recording"></i> {{$t('campaigns.edit_campaign_settings_videocalll')}}</span>
        </template>
        <div class="row pt-5">
          <div class="col-sm-6">
            <div class="mb-3">
              <label for="predefinedPicture">{{$t('campaigns.edit_campaign_settings_videocall_PredefinedImageLabel')}}</label>
                <input
                  ref="predefinedPicture"
                  @change="updatePredefinedPicture"
                  accept="image/*"
                  class="form-control "
                  id="predefinedPicture"
                  type="file"/>
            </div>
          </div>
          <div class="col-sm-6 flex" v-if="predefinedPicture">
            <div class="avatar-xs me-3 edit-picture-button" v-on:click="removePredefinedPicture">
              <span class="avatar-title bg-light rounded-circle font-size-15 icon-color-grey">
                <i class="bx bxs-trash"></i>
              </span>
            </div>
            <img
              class="rounded me-2"
              height="120"
              :src="predefinedPicture"
              data-holder-rendered="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="role">{{$t('campaigns.edit_campaign_settings_videocall_noCameraLabel')}}</label>
              <b-input-group>
              <b-form-input
                v-model="noCameraLabel"
                type="text">
              </b-form-input>
              <b-input-group-append>
                  <b-button variant="primary" @click="onNoCameraLabelClicked"><i class="bx bx-save"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
           </div>
        </div>
        <b-form-group
          class="mb-3"
          id="example-color"
          label-cols-sm="2"
          label-cols-lg="2"
          label="Color"
          label-for="color"
        >
          <b-form-input
            v-model="accentColor"
            class="form-control-color mw-100"
            id="color"
            type="color"
            name="color"
            @change="onColorChanged"
          ></b-form-input>
        </b-form-group>

        <div class="row pt-3">
          <div class="col-sm-6">
            <div class="mb-3">
              <b-form-checkbox
                    v-model="useConfirmExit"
                    class="mb-3"
                    checked
                    @change="onuseConfirmExitChecked"
                    plain
                    >{{$t('campaigns.edit_campaign_settings_videocall_useConfirmExit')}}</b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-sm-6">
            <div class="mb-3">
              <b-form-checkbox
                    v-model="useRate"
                    class="mb-3"
                    checked
                    @change="onUseRateChecked"
                    plain
                    >{{$t('campaigns.edit_campaign_settings_videocall_useRate')}}</b-form-checkbox>
            </div>
          </div>
        </div> 
        <div class="row pt-3">
          <div class="col-sm-6">
            <div class="mb-3">
              <b-form-checkbox
                    v-model="useAdminTagsAndComments"
                    class="mb-3"
                    checked
                    @change="onUseAdminTagsAndCommentsChecked"
                    plain
                    >{{$t('campaigns.edit_campaign_settings_videocall_useAdminTagsAndComments')}}</b-form-checkbox>
            </div>
          </div>
        </div> 
        <div class="row pt-3">
          <div class="col-sm-6">
            <div class="mb-3">
              <b-form-checkbox
                    v-model="useAddToCartNotification"
                    class="mb-3"
                    checked
                    @change="onUseAddToCartNotificationChanged"
                    plain>{{$t('campaigns.edit_campaign_settings_videocall_useAddToCartNotification')}}</b-form-checkbox>
            </div>
          </div>
        </div> 
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="bx bx-send"></i>
          </span>
          <span class="d-none d-sm-inline-block"> <i class="bx bx-send"></i> {{$t('campaigns.edit_campaign_settings_invitation')}}</span>
        </template>
        <div class="row pt-3">
          <div class="col-sm-6">
            <div class="mb-3">
              <b-form-checkbox
                    v-model="sendEmailInvitation"
                    class="mb-3"
                    checked
                    @change="onSendInvitationByEmailChecked"
                    plain
                    >{{$t('campaigns.edit_campaign_settings_invitation_sendByEmail')}}</b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="row" v-if="sendEmailInvitation">
          <div class="col-sm-6">
            <div class="mb-3">
              <label for="originEmail">{{$t('campaigns.edit_campaign_settings_invitation_FromEmail')}}</label>
                <input
                  v-model="invitation.fromEmail"
                  ref="originEmail"
                  class="form-control "
                  id="originEmail"
                  type="text"
                />
            </div>
          </div>
        </div>

        <div class="row" v-if="sendEmailInvitation">
          <div class="col-sm-6">
            <div class="mb-3">
              <label for="originName">{{$t('campaigns.edit_campaign_settings_invitation_FromName')}}</label>
                <input
                  v-model="invitation.fromName"
                  ref="originName"
                  class="form-control "
                  id="originName"
                  type="text"
                />
            </div>
          </div>
        </div>

        <div class="row" v-if="sendEmailInvitation">
          <div class="col-sm-6">
            <div class="mb-3">
              <label for="subjectInput">{{$t('campaigns.edit_campaign_settings_invitation_Subject')}}</label>
                <input
                  v-model="invitation.subject"
                  class="form-control "
                  id="subjectInput"
                  type="text"
                />
            </div>
          </div>
        </div>

        <div class="row" v-if="sendEmailInvitation">
          <div class="col-sm-6">
            <div class="mb-3">
              <label for="subjectInput">{{$t('campaigns.edit_campaign_settings_invitation_Body')}}</label>
                <ckeditor v-model="invitation.body" :editor="editor"></ckeditor>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
				<div class="text-end">
					<b-button variant="primary" class="w-md ms-3 mb-3" @click="onSaveInvitationSettings">{{$t('common.confirm')}}</b-button>
				</div>
			</div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style scoped>

.edit-picture-button {
    height: 1.5rem !important;
    width: 1.5rem !important;
    top: -5px;
    position: absolute;
    color:#adb5bd
}

.icon-color-grey {
  color:#adb5bd
}
</style>