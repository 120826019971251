<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Products from "../../../components/campaigns/products"
import Settings from "../../../components/campaigns/settings"
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import _debounce from 'lodash.debounce';
import DatePicker from "vue2-datepicker";

import {
	invitationsMethods,
	eventMethods,
	groupMethods,
	campaignMethods,
	projectMethods,
	commonMethods,
	availabilityMethods,
} from "@/state/helpers";

/**
 * Edit campaign Component
 */
export default {
	page: {
		title: "Edit Campaign",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
		Products,
		Settings,
		Multiselect,
		DatePicker
	},
	data() {
		return {
			title: "",
			items: [],
			showModalHost: false,
			showModalProducts:false,
			isLoading: false,
			fullPage: true,
			canCancel: false,
			useSlot: false,
			color: "#007bff",
			bgColor: "#ffffff",
			height: 128,
			width: 128,
			groups:[],
			projects:[],
			campaign:{},
			submitted:false,
			campaignPicture : null,
			selectedProduct:null,
			selectedProducts:[],
			products:[],
			loadingProducts:false,
			invitation: {
				sendEmailInvitation:true,
				fromEmail: "noreply@email.goshops.com",
				fromName: "Go live-shop", 
				subject : "Invitación a cita virtual",
				body : null
			},
			campaignDuration : -1,
			campaignScheduleUrl : window.config.VUE_CALENDAR_URL,
			campaignAndhostScheduleUrl : `${window.config.VUE_CALENDAR_URL}?host=`,
			calendarLogoPicture: null,
			availabilities:[],
			invitations:[],
			isAdmin : JSON.parse(localStorage.getItem("auth.currentUser")).user.role && JSON.parse(localStorage.getItem("auth.currentUser")).user.role == "admin",
			useInvitations: false,
			currentPage:1,
			perPage:25,
			rows:0,
			useOnlyNotScheduled:false,
			user: JSON.parse(localStorage.getItem("auth.currentUser")).user,
			invitationsWebhook:null,
			totalEventsBySlot: null,
			startDate:null,
			endDate:null
		}
	},
	validations: {
		campaign: {
			name: {
				required,
			},
			description: {
				required,
			},
			startDate: {
				required,
			},
			endDate: {
				required,
			},
			group:{
				required
			},
			project:{
				required
			}
		},
	},
	mounted() {
		this.loadGroups();
		this.loadProjects();
		this.loadAvailabilities();

		const campaignId = this.$route.query.id;
		
		if(campaignId){
			this.title = this.$t("campaigns.edit_campaign");
			let loader = this.$loading.show();

			this.getCampaign(campaignId).then((res)=>{
				this.campaign = res.data;
				//this.campaign.startDate = this.formatDate(this.campaign.startDate);
				//this.campaign.endDate = this.formatDate(this.campaign.endDate);

				this.startDate = new Date(this.campaign.startDate);
				this.endDate = new Date(this.campaign.endDate);

				this.campaignPicture = this.campaign.imageURL;
				this.selectedProducts = this.campaign.items;
				this.title = res.data.name;
				this.campaignDuration = (this.campaign.durationHour * 60 ) + this.campaign.durationMinutes;

				this.calendarLogoPicture = this.campaign.settings && this.campaign.settings.calendar &&  this.campaign.settings.calendar.logo ? this.campaign.settings.calendar.logo : null ;

				this.campaignScheduleUrl = this.campaign && this.campaign._id ? this.campaignScheduleUrl+'?campaign=' + this.campaign._id : this.campaignScheduleUrl;
				this.campaignAndhostScheduleUrl = this.campaign && this.campaign._id ? this.campaignAndhostScheduleUrl+ this.user?._id + '&campaign=' + this.campaign._id : this.campaignAndhostScheduleUrl;

				this.invitation = this.campaign.settings && this.campaign.settings.invitation ? this.campaign.settings.invitation: this.invitation ;
				

				if(this.isAdmin){
					this.loadInvitations();
					this.useInvitations = this.campaign.settings && this.campaign.settings.calendar &&  this.campaign.settings.calendar.useInvitations ? this.campaign.settings.calendar.useInvitations : false ;
					this.invitationsWebhook = this.campaign.settings?.calendar?.invitationsWebhook; 
					this.totalEventsBySlot = this.campaign.settings?.calendar?.totalEventsBySlot;
					//this.invitations = this.campaign.settings && this.campaign.settings.calendar &&  this.campaign.settings.calendar.invitations?  this.campaign.settings.calendar.invitations : [] ;
					//this.loadEvents();
				}
				
			}).catch(()=>{

			}).finally(()=>{
				loader.hide();
			})
		}
		else{
			this.onCancelCreateOrEditCampaignClicked();
		}
	},
	created() {
		this.debounceProducts = _debounce(this.searchProduct.bind(this), 1000);
		this.debounceInvitations = _debounce(this.searchInvitations.bind(this), 1000);
	},
	methods: {
		...campaignMethods,
		...groupMethods,
		...projectMethods,
		...commonMethods,
		...availabilityMethods,
		...eventMethods,
		...invitationsMethods, 

		copyCampaignSchedulelLink() {
			navigator.clipboard.writeText(this.campaignScheduleUrl);
		},
		copyCampaignAndHostSchedulelLink() {
			navigator.clipboard.writeText(this.campaignAndhostScheduleUrl);
		},

		saveCampaignDuration() {
			this.campaign.durationHour = Math.trunc(this.campaignDuration / 60);
			this.campaign.durationMinutes = this.campaignDuration - (this.campaign.durationHour * 60);

			this.onConfirmEditCampaignClicked()
		},

		loadAvailabilities(){
			let loader = this.$loading.show();

			const params={
				q: `limit=100`,
			}

			this.getAvailabilities(params).then((availabilities)=>{
				if(availabilities&& availabilities.data){
					this.availabilities= availabilities.data;
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("availability.get_availability_error"),title:  this.$t("availability.title") });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
		},

		loadProjects(){
			let loader = this.$loading.show();
			
			this.getProjects().then((projects)=>{
				if(projects&& projects.data){
				this.projects= projects.data;
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("projects.get_projects_error"),title:  this.$t("projects.title") });
			}).finally(()=>{
				loader.hide();
			});
		},
		
		onCancelCreateOrEditCampaignClicked() {
			this.$router.push(
				{
					path: "/campaigns",
				}
			).catch(() => {});
		},

		onConfirmCreateOrEditCampaignClicked(){
			const campaignId = this.$route.query.id;

			if(campaignId){
				this.onConfirmEditCampaignClicked()
			}
		},

		onConfirmEditCampaignClicked(){
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			let newCampaign = Object.assign({}, this.campaign);

			delete newCampaign.products;
			delete newCampaign.items; 
			delete newCampaign.settings; 

			let loader = this.$loading.show();
				
			//this.campaign.settings = this.campaign.settings || {};
			//this.campaign.settings.invitation = this.invitation; 

			this.updateCampaign(newCampaign).then(()=>{
				this.title = this.campaign.name;
				this.$notify({ type: 'success', text: this.$t("campaigns.edit_campaign_success"),title:  this.$t("campaigns.edit_campaign") });
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("campaigns.edit_campaign_error"),title:  this.$t("campaigns.edit_campaign") });
			}).finally(()=>{
				loader.hide();
			})
		},

		updateCampaignPicture() {
			var file = this.$refs.campaignPicture.files[0];
			this.$refs.campaignPicture.files = null;

			if(file){

				this.uploadCampaignPicture(file)
				.then((res) => {
					this.campaign.imageURL = res.data.url;
					this.campaignPicture = this.campaign.imageURL;
					return true;                  
				})
				.catch(() => {
					this.$notify({ type: 'error', text: this.$t("campaigns.updateCampaignPicture_error"),title:  this.$t("campaigns.new_campaign") });
				})
			}
		},

		formatDate(date) {
			var d = new Date(date);
			let month = '' + (d.getMonth() + 1);
			let day = '' + d.getDate();
			let year = d.getFullYear();

			if (month.length < 2) 
				month = '0' + month;
			if (day.length < 2) 
				day = '0' + day;
			
			return [year, month, day].join('-');
		},

		loadGroups(){
			let loader = this.$loading.show();

			this.getGroups().then((groups)=>{
				if(groups&& groups.data){
					this.submitted= false;
					this.groups= groups.data;
				}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("groups.get_groups_error"),title:  this.$t("groups.title") });
			}).finally(()=>{
				loader.hide();
			});
		},
		
		onSelectProductsClicked(){
			const campaignId = this.$route.query.id;

			if(this.selectedProducts.filter(p=>p._id == this.selectedProduct._id).length == 0) {

				this.selectedProducts.push(this.selectedProduct);

				if(campaignId){
					let params = {
						_id: campaignId,
						payload: {
							"$addToSet" :
							{ "items" : this.selectedProduct._id }
						}
					}

					let loader = this.$loading.show();

					this.updateProductCampaign(params).then(()=>{
						this.$notify({ type: 'success', text: this.$t("campaigns.edit_campaign_add_product_success"),title:  this.$t("campaigns.edit_campaign") });
					}).catch(()=>{
						this.$notify({ type: 'error', text: this.$t("campaigns.edit_campaign_add_product_error"),title:  this.$t("campaigns.edit_campaign") });
					}).finally(()=>{
						loader.hide();
					})
				}
			}
			
			this.selectedProduct = null;
		},

		nameWithSKU(p) {
			return `${p.data.sku} — [${p.data.name}]`
		},
		
		handleRemoveProduct(product) {
			this.selectedProducts = this.selectedProducts.filter(u => u._id!=product._id);

			const campaignId = this.$route.query.id;

			if(campaignId){
				let params = {
					_id: campaignId,
					payload: {
						"$pull" :
						{ "items" : product._id }
					}
				}

				let loader = this.$loading.show();

				this.updateProductCampaign(params).then(()=>{
					this.$notify({ type: 'success', text: this.$t("campaigns.edit_campaign_remove_product_success"),title:  this.$t("campaigns.edit_campaign") });
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t("campaigns.edit_campaign_remove_product_error"),title:  this.$t("campaigns.edit_campaign") });
				}).finally(()=>{
					loader.hide();
				})
			}
		},
		
		searchProduct(query){
			
			this.isLoading = true;

			if(query){
				this.products=[];
				const params ={
					q: `whereObj={"project":"${this.campaign.project._id}", "data.active" : 1, "$or": [{"data.name" : {"$regex" : ".*${query}*." , "$options": "i"} }, {"data.sku" : {"$regex" : ".*${query}*." , "$options": "i"}} ]}`
				}

				this.getItems(params).then((products)=>{
					if(products&& products.data){
						this.products= products.data;
					}
					this.isLoading = false;
				})
			}else{
				this.products = [];
				this.isLoading = false;
				this.selectedProduct=null;
			}
		},

		updateCampaignSettings(){
			let body = {
				_id: this.campaign._id,
				settings: this.campaign.settings 
			}

			let loader = this.$loading.show();
			this.updateCampaign(body).then(()=>{
				this.$notify({ type: 'success', text: this.$t("campaigns.edit_campaign_success"),title:  this.$t("campaigns.edit_campaign") });

			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("campaigns.edit_campaign_error"),title:  this.$t("campaigns.edit_campaign") });
			}).finally(()=>{
				loader.hide();
			})
		},

		updateCalendarLogoPicture() {
			var file = this.$refs.calendarLogoPicture.files[0];
			this.$refs.calendarLogoPicture.files = null;

			if(file){
				this.uploadMedia(file).then((res) => {
					if(!this.campaign.settings || !this.campaign.settings.calendar){
						this.campaign.settings = {
							calendar:{}
						}
					}

					this.campaign.settings.calendar.logo = res.data.url;
					this.calendarLogoPicture = this.campaign.settings.calendar.logo;

					this.updateCampaignSettings();

					return true;                  
				})
				.catch((err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					this.$notify({ type: 'error', text: this.$t("campaigns.updateCampaignPicture_error"),title:  this.$t("campaigns.new_campaign") });
				})
			}
		},
		removeCalendarLogoPicture(){
			this.campaign.settings.calendar.logo = null;
			this.calendarLogoPicture = null;

			this.updateCampaignSettings();
		},
		onAvailabilitySelected(){

			let loader = this.$loading.show();
			
			const campaignToUpdate = {
				_id: this.campaign._id,
				availability: this.campaign.availability
			}

			this.updateCampaign(campaignToUpdate).then(()=>{
				this.$notify({ type: 'success', text: this.$t("campaigns.edit_campaign_success"),title:  this.$t("campaigns.edit_campaign") });
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("campaigns.edit_campaign_error"),title:  this.$t("campaigns.edit_campaign") });
			}).finally(()=>{
				loader.hide();
			})
		},

		onUseInvitationsChecked(){
			this.campaign.settings = this.campaign.settings || {};
			this.campaign.settings.calendar = this.campaign.settings.calendar || {}

			this.campaign.settings.calendar.useInvitations = this.useInvitations;

			this.updateCampaignSettings();
		},

		loadInvitations(){

			let loader = this.$loading.show();

			const params = {
				q: !this.useOnlyNotScheduled ? `whereObj={ "campaign": "${this.campaign._id}"}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}` : `&whereObj={ "scheduled": false, "campaign": "${this.campaign._id}"}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
			}
			this.getInvitations(params).then((res)=> {
				this.invitations = res.data;
				this.rows = res.totalCount
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("invitations.get_invitations_error"),title:  this.$t("invitations.title") });
			}).finally(()=>{
				loader.hide();
			});
		},

		onInvitationsListPageClicked(){

			if(this.query && this.query !=''){
                this.searchInvitations(this.query);
            }else{
                this.loadInvitations();    
            }
		},

		updateCampaignInvitations(e){
			var file = e.target.files[0];
			if (!file) {
				return;
			}
			e.target.files = null;
			let loader = this.$loading.show();
			const payload = {
				campaign: this.campaign._id,
				file
			}

			this.uploadInvitations(payload).then(()=>{
				this.$notify({ type: 'success', text: this.$t("invitations.upload_invitations_success"),title:  this.$t("invitations.title") });
				this.loadInvitations();
				this.$refs.campaignIvitations.files = null;
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("invitations.upload_invitations_error"),title:  this.$t("invitations.title") });
			}).finally(()=>{
				loader.hide();
			});
		},
		onRemoveInvitationClicked(invitation){

			let loader = this.$loading.show();

			this.deleteInvitation(invitation._id).then(()=>{
				this.loadInvitations();
			}).catch((ex)=>{
				// eslint-disable-next-line no-console
				console.log(ex);
			}).finally(()=>{
				loader.hide();
			});

		},
		searchInvitations(query){

			let loader = this.$loading.show();
			this.isLoading = true;

			if(query){
				this.invitations=[];
				this.currentPage = 1;
				const params={
					q: !this.useOnlyNotScheduled ?`whereObj={"$or": [{"name" : {"$regex" : ".*${query}*." , "$options": "i"} }, {"email" : {"$regex" : ".*${query}*." , "$options": "i"}} ], "campaign" : "${this.campaign._id}"}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}` : `whereObj={"$or": [{"name" : {"$regex" : ".*${query}*." , "$options": "i"} }, {"email" : {"$regex" : ".*${query}*." , "$options": "i"}} ], "scheduled": false, "campaign" : "${this.campaign._id}"}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
				}
				this.getInvitations(params).then((invitations)=>{
					if(invitations&& invitations.data){
						this.invitations= invitations.data;
						this.rows= invitations.totalCount;
					}
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t("invitations.get_invitations_error"),title:  this.$t("invitations.title") });
				}).finally(()=>{
					loader.hide();
				});
			}else{
				loader.hide();
				this.currentPage = 1;
				this.loadInvitations();
			}
		},

		onUseOnlyNotScheduledChanged(){
			this.query = ''
            this.currentPage = 1;
            this.loadInvitations();
		},

		onWebhookInvitationsChanged(){
			this.campaign.settings = this.campaign.settings || {};
			this.campaign.settings.calendar = this.campaign.settings.calendar || {}

			this.campaign.settings.calendar.invitationsWebhook = this.invitationsWebhook;

			this.updateCampaignSettings();
		},

		onTotalEventsBySlotChanged(){
			this.campaign.settings = this.campaign.settings || {};
			this.campaign.settings.calendar = this.campaign.settings.calendar || {}

			this.campaign.settings.calendar.totalEventsBySlot = this.totalEventsBySlot;

			this.updateCampaignSettings();
		},

		startDateChanged(){
			this.campaign.startDate= this.startDate;
			
		},
		endDateChanged(){
			this.campaign.endDate= this.endDate;
		},
		onExportInvitations(){
			let loader = this.$loading.show();

			const params = {
				q: `whereObj={ "campaign": "${this.campaign._id}"}&limit=1000`
			}
			this.getInvitations(params).then((res)=> {
				const invitations = res.data; 
				if(invitations && invitations.length> 0){
					
					this.createCSVWithInvitations(invitations);
					
				}
				
				
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("invitations.get_invitations_error"),title:  this.$t("invitations.title") });
			}).finally(()=>{
				loader.hide();
			});
		},
		createCSVWithInvitations(invitations){
			const toExport = [];
			invitations.forEach((i)=>{
				toExport.push({
					"Nombre" : i.name,
					"Email" : i.email,
					"Agendado" : i.scheduled ? "SI" : "NO",
					"Fecha": i.scheduled && i.date ? `${new Date(i.date).toLocaleDateString()} ${new Date(i.date).toLocaleTimeString()}` : '',
					'Host' : i.host || '',
					'Link' : i.link || ''
				})
			})
			let csvContent = "data:text/csv;charset=utf-8,";
			csvContent += [
        Object.keys(toExport[0]).join(";"),
        ...toExport.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", `${this.campaign.name}_export_invitations_${new Date().toLocaleDateString()}.csv`);
      link.click();
		},


createCSVWithEvents(events){
      const toExport = [];
      events.forEach((i)=>{
        toExport.push({
          "Nombre" : i.invites[0].name,
          "Email" : i.invites[0].email,
          "Fecha": `${new Date(i.date).toLocaleDateString()} ${new Date(i.date).toLocaleTimeString()}`,
          "host" : i.host.name,
          "Link" : i.url,
          "phone": i.invites[0].tel || '',
          "urlHost" :i.urlHost

        })
      })
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(toExport[0]).join(";"),
        ...toExport.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", `${this.campaign.name}_export_events_${new Date().toLocaleDateString()}.csv`);
      link.click();
    },


onExportEvents(){
      let loader = this.$loading.show();

      const params = {
        q: `whereObj={ "campaign": "${this.campaign._id}", "status" : "scheduled"}&limit=1000&sortObj={"date":1}`
      }
      this.getEvents(params).then((res)=> {
        const events = res.data; 
        if(events && events.length> 0){
          this.createCSVWithEvents(events);
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("invitations.get_invitations_error"),title:  this.$t("invitations.title") });
      }).finally(()=>{
        loader.hide();
      });
    },





	},
	
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="checkout-tabs">
			<b-tabs
				pills
				vertical
				nav-class="p-0"
				nav-wrapper-class="col-xl-2 col-sm-3">
				<b-tab active>
					<template v-slot:title>
						<i class="bx bxs-briefcase-alt d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('campaigns.new_campaign_basicInfo')}}</p>
					</template>
					<b-card-text>
						<div class="card">
							<div class="card-body">
								<h4 class="card-title">{{$t('campaigns.new_campaign_basicInfo')}}</h4>
								<p class="card-title-desc">{{$t('campaigns.new_campaign_basicInfo_subtitle')}}</p>
								<div class="row">
									<div class="col">
										<div class="mb-3">
											<label for="campaignName">{{$t('campaigns.new_campaign_basicInfo_name')}}*</label>
											<input
												id="campaignName"
												v-model="campaign.name"
												name="name"
												type="text"
												class="form-control"
												:class="{
													'is-invalid': submitted && $v.campaign.name.$error,}"
											/>
											<div v-if="submitted && !$v.campaign.name.required" class="invalid-feedback">
													{{$t('campaigns.new_campaign_basicInfo_name_required')}}
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<div class="mb-3">
											<label for="campaignDesc">{{$t('campaigns.new_campaign_basicInfo_description')}}*</label>
											<textarea
												id="campaignDesc"
												v-model="campaign.description"
												class="form-control"
												:class="{
												'is-invalid': submitted && $v.campaign.description.$error,}"
												rows="2">
											</textarea>
											<div v-if="submitted && !$v.campaign.description.required" class="invalid-feedback">
													{{$t('campaigns.new_campaign_basicInfo_description_required')}}
											</div>
										</div>
									</div>  
								</div>
								<div class="row">
									<div class="col-sm-6">
										<div class="mb-3">
											<label for="campaignStartDate">{{$t('campaigns.new_campaign_basicInfo_startDate')}}*</label>
											<date-picker
												id="campaignStartDate"
												v-model="startDate"
												name="campaignStartDate"
												type="date"
												:class="{'is-invalid': submitted && $v.campaign.startDate.$error,}"
												@input="startDateChanged"
											/>
											<div
												v-if="submitted && !$v.campaign.startDate.required"
												class="invalid-feedback">
													{{$t('campaigns.new_campaign_basicInfo_startDate_required')}}
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="mb-3">
											<label for="campaignEndDate">{{$t('campaigns.new_campaign_basicInfo_endDate')}}*</label>
											<date-picker
												id="campaignEndDate"
												v-model="endDate"
												name="campaignEndDate"
												type="date"
												:class="{'is-invalid': submitted && $v.campaign.endDate.$error,}"
												@input="endDateChanged"
											/>
											<div
												v-if="submitted && !$v.campaign.endDate.required"
												class="invalid-feedback">
													{{$t('campaigns.new_campaign_basicInfo_endDate_required')}}
											</div>
										</div> 
									</div> 
								</div>
								<div class="row">
									<div class="col">
										<div class="mb-3">
											<label for="role">{{$t('campaigns.new_campaign_basicInfo_project')}}*</label>
											<multiselect 
												v-model="campaign.project"
												:options="projects"
												:selectLabel="$t('common.select')"
												:deselectLabel="$t('common.deselect')"
												:placeholder="$t('dashboard.project_placeholder')"
												:multiple="false"
												track-by="_id" 
												label="name"
												:showNoResults="false"
												:class="{ 'is-invalid': submitted && $v.campaign.project.$error}">
											</multiselect>
											<div v-if="submitted && !$v.campaign.project.required" class="invalid-feedback">
												{{$t('campaigns.new_campaign_basicInfo_project_required')}}
											</div>
										</div>
									</div>
								</div>   

								<div class="row">
									<div class="col">
										<div class="mb-3">
											<label for="role">{{$t('campaigns.new_campaign_basicInfo_group')}}*</label>
											<multiselect 
												v-model="campaign.group"
												:options="groups"
												:selectLabel="$t('common.select')"
												:deselectLabel="$t('common.deselect')"
												:placeholder="$t('users.group_placeholder')"
												:multiple="false"
												track-by="_id" 
												label="name"
												:showNoResults="false"
												:class="{ 'is-invalid': submitted && $v.campaign.group.$error}">
											</multiselect>
												<div v-if="submitted && !$v.campaign.group.required" class="invalid-feedback">
														{{$t('campaigns.new_campaign_basicInfo_group_required')}}
												</div>
										</div>
									</div>
								</div>  
								<div class="row">
									<div class="col-sm-6">
										<div class="mb-3">
											<label for="campaignImage">{{$t('campaigns.new_campaign_basicInfo_image')}}</label>
											<input
												ref="campaignPicture"
												@change="updateCampaignPicture"
												accept="image/*"
												class="form-control "
												id="campaignImage"
												type="file"
											/>
										</div>
									</div>
									<div class="col-sm-6 flex" v-if="campaign._id && campaignPicture">
										<div class="avatar-xs me-3 edit-picture-button" v-on:click="campaignPicture = null">
											<span class="avatar-title bg-light rounded-circle font-size-15 icon-color-grey">
												<i class="bx bxs-trash"></i>
											</span>
										</div>
										<img
											class="rounded me-2"
											height="120"
											:src="campaignPicture"
											data-holder-rendered="true"
										/>
									</div>
								</div>
							</div>
						</div>
					</b-card-text>
				</b-tab>
				<b-tab>
					<template v-slot:title>
						<i class="bx bxs-purchase-tag-alt d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('campaigns.new_campaign_products')}}</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">{{$t('campaigns.new_campaign_products')}}</h4>
							<p class="card-title-desc">{{$t('campaigns.new_campaign_products_subtitle')}}</p>
							<div class="row mb-3">
								<div class="col-sm-6 text-sm-end">
									<b-spinner
										v-if="isLoading"
										class="m-2"
										variant="primary"
										role="status">
									</b-spinner>
								</div>
								<div class="col-sm-6">
									<div class="text-sm-end">
										<multiselect 
											v-model="selectedProduct" 
											id="products" 
											label="name" 
											track-by="_id" 
											:selectLabel="$t('common.select')"
											:deselectLabel="$t('common.deselect')"
											:placeholder="$t('campaigns.new_campaign_products_select')"
											open-direction="bottom" 
											:options="products" 
											:multiple="false" 
											:searchable="true" 
											:internal-search="false" 
											:clear-on-select="false" 
											:close-on-select="true" 
											:options-limit="300" 
											:limit="1" 
											:max-height="600" 
											:show-no-results="false" 
											:hide-selected="false"
											@search-change="debounceProducts"
											@input="onSelectProductsClicked"
											:custom-label="nameWithSKU">
											<template v-slot:noOptions>
												{{$t('campaigns.new_campaign_products_empty')}}
											</template>
										</multiselect>
									</div>
								</div>
							</div>
							<Products :products="selectedProducts" :showActions="true" @removeProduct="handleRemoveProduct" :showStock="false" />
						</div>
					</div>
				</b-tab>
				<b-tab v-if="campaign && campaign._id && isAdmin" >
					<template v-slot:title>
						<i class="bx bxs-wrench d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('campaigns.edit_campaign_settings_title')}}</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">{{$t('campaigns.edit_campaign_settings_title')}}</h4>
							<p class="card-title-desc">{{$t('campaigns.edit_campaign_settings_subtitle')}}</p>
							<Settings :campaign="campaign" :invitation="invitation"/>
						</div>
					</div>
				</b-tab>
				<b-tab v-if="campaign && campaign._id">
					<template v-slot:title>
						<i class="bx bx-calendar d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{ $t("campaigns.edit_campaign_scheduler") }}</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">{{ $t("campaigns.edit_campaign_scheduler") }}</h4>
							<p class="card-title-desc">{{ $t("campaigns.edit_campaign_scheduler_subtitle") }}</p>
							<div>

								<b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
									<b-tab active>
										<template v-slot:title>
										<span class="d-inline-block d-sm-none">
											<i class="bx bx-cog"/>
										</span>
										<span class="d-none d-sm-inline-block"> <i class="bx bx-cog"></i> General</span>
										</template>
										<div class="row">
											<div class="col-6">
												<div class="mb-3">
												<label for="role">{{$t('events.new_event_duration')}}</label>
												<b-input-group>
													<b-form-input
														v-model="campaignDuration"
														type="number"
														name="number">
													</b-form-input>
													<b-input-group-append>
														<b-button variant="primary" @click="saveCampaignDuration"><i class="bx bx-save"></i></b-button>
													</b-input-group-append>
												</b-input-group>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-6">
												<div class="mb-3">
													<label for="role">{{$t('availability.title')}}</label>
													<multiselect 
														v-model="campaign.availability"
														:options="availabilities"
														:selectLabel="$t('common.select')"
														:deselectLabel="$t('common.deselect')"
														:placeholder="$t('groups.availability_placeholder')"
														:multiple="false"
														track-by="_id" 
														label="name"
														:showNoResults="false"
														@input="onAvailabilitySelected">
													</multiselect>
												</div>
											</div>
										</div>  
										<div class="row">
											<div class="col-sm-6">
												<div class="mb-3">
												<label>Logo</label>
													<input
													ref="calendarLogoPicture"
													@change="updateCalendarLogoPicture"
													accept="image/*"
													class="form-control "
													id="calendarLogoPicture"
													type="file"/>
												</div>
											</div>
										<div class="col-sm-6 flex" v-if="calendarLogoPicture">
											<div class="avatar-xs me-3 edit-picture-button" v-on:click="removeCalendarLogoPicture">
											<span class="avatar-title bg-light rounded-circle font-size-15 icon-color-grey">
												<i class="bx bxs-trash"></i>
											</span>
											</div>
											<img
											class="rounded me-2"
											height="120"
											:src="calendarLogoPicture"
											data-holder-rendered="true"
											/>
										</div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="mb-3">
												<label for="role">{{$t("campaigns.edit_campaign_settings_campaign_calendar")}}</label>
												<b-input-group prepend="🗓️">
												<b-form-input
													v-model="campaignScheduleUrl"
													type="text"
													disabled>
												</b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="copyCampaignSchedulelLink"><i class="bx bx-copy"></i></b-button>
													</b-input-group-append>
												</b-input-group>
												</div>
											</div>
										</div> 
										<div class="row" v-if="!isAdmin">
											<div class="col-6">
												<div class="mb-3">
													<label for="role">{{$t("campaigns.edit_campaign_settings_campaign_host_calendar")}}</label>
													<b-input-group prepend="🗓️">
														<b-form-input
														v-model="campaignAndhostScheduleUrl"
														type="text"
														disabled>
														</b-form-input>
														<b-input-group-append>
														<b-button variant="primary" @click="copyCampaignAndHostSchedulelLink"><i class="bx bx-copy"></i></b-button>
														</b-input-group-append>
													</b-input-group>
												</div>
											</div>
										</div>
									</b-tab>
									<b-tab>
										<template v-slot:title>
										<span class="d-inline-block d-sm-none">
											<i class="bx bx-calendar-check"></i>
										</span>
										<span class="d-none d-sm-inline-block"> <i class="bx bx-calendar-check"></i> Avanzado</span>
										</template>
										<div class="row pt-3">
											<div class="col-sm-6">
												<div class="mb-3">
												<b-form-checkbox
														v-model="useInvitations"
														class="mb-3"
														checked
														@change="onUseInvitationsChecked"
														plain>{{$t("campaigns.edit_campaign_settings_calendar_use_invitations")}}</b-form-checkbox>
												</div>
											</div>
										</div> 
										<div class="row" v-if="useInvitations">
											<div class="col-6">
												<div class="mb-3">
												<label for="role">Webhook invitaciones</label>
												<b-input-group>
												<b-form-input
													v-model="invitationsWebhook"
													type="text">
												</b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="onWebhookInvitationsChanged"><i class="bx bx-save"></i></b-button>
													</b-input-group-append>
												</b-input-group>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="mb-3">
												<label for="role">{{$t("campaigns.edit_campaign_settings_calendar_max_events_by_slot")}}</label>
												<b-input-group>
												<b-form-input
													v-model="totalEventsBySlot"
													type="number">
												</b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="onTotalEventsBySlotChanged"><i class="bx bx-save"></i></b-button>
													</b-input-group-append>
												</b-input-group>
												</div>
											</div>
										</div>
									</b-tab>
									<b-tab v-if="isAdmin && useInvitations">
										<template v-slot:title>
										<span class="d-inline-block d-sm-none">
											<i class="bx bx-envelope"></i>
										</span>
										<span class="d-none d-sm-inline-block"> <i class="bx bx-envelope"></i> {{$t("invitations.title")}}</span>
										</template>
										<div class="row">
											<div class="col-sm-6">
												<div class="mb-3">
													<label>{{$t("invitations.add_invitations")}}</label>
													<input
														ref="campaignIvitations"
														@change="updateCampaignInvitations"
														accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
														class="form-control "
														type="file"
													/>
												</div>
											</div>

											<div class="row mb-2" >
												<div class="col-sm-3 text-right">
													<div class="search-box me-2 mb-2 d-inline-block">
														<div class="position-relative">
															<input
																v-model="query"
																type="text"
																class="form-control"
																:placeholder="$t('common.search')"
																@input="debounceInvitations($event.target.value)"
															/>
															<i class="bx bx-search-alt search-icon"></i>
														</div>
													</div>
												</div>

												<div class="col-sm-5"></div>
												<div class="col-sm-4 mt-2 text-end d-inline-flex align-baseline" >
													
													<b-form-checkbox
														v-model="useOnlyNotScheduled"
														class="mb-0 mt-2"
														checked
														@change="onUseOnlyNotScheduledChanged"
														plain>
														{{$t("invitations.only_not_scheduled")}}
													</b-form-checkbox>

													<div class="col text-sm-end">
														<b-dropdown variant="primary" >
																<template v-slot:button-content class="btn-link">
																	Exportar
																</template>
																<b-dropdown-item v-on:click="onExportInvitations">Invitaciones</b-dropdown-item>
																<b-dropdown-item v-on:click="onExportEvents">Eventos</b-dropdown-item>
														</b-dropdown>
													</div>
												</div>
												
											</div>
											<table  id="my-table" class="table align-middle table-nowrap table-hover">
												<thead class="table-light">
													<tr>
														<th scope="col">{{$t("invitations.name")}}</th>
														<th scope="col">{{$t("invitations.email")}}</th>
														<th scope="col">{{$t("invitations.scheduled")}}</th>
														<th scope="col"></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="invitation in invitations" :key="invitation._id">
														<td>{{invitation.name}}</td>
														<td>{{invitation.email}}</td>
														<td> 
														<span class="badge font-size-10"
															:class="{
															'bg-success': `${invitation.scheduled}` === 'true',
															'bg-warning': `${invitation.scheduled}` === 'false' &&  `${invitation.status}` != 'rejected',
															'bg-danger': `${invitation.status}` === 'rejected',
															}">
																{{ invitation.status && invitation.status == 'rejected' ? $t('invitations.rejected') :  invitation.scheduled ? $t('common.yes') : $t('common.no')}}
														</span>

														</td>
														<td> <i class= 'bx bx-trash interact text-danger' v-on:click="onRemoveInvitationClicked(invitation)" v-if="!invitation.scheduled" /> </td>
													</tr>
												</tbody>
											</table>
											<p class="fw-bold">{{ $t('invitations.invitations_total').replace('{0}',this.rows)}}</p>
											<div class="row mb-4" v-if="rows > perPage">
												<div class="col">
													<div class="dataTables_paginate paging_simple_numbers float-end">
														<ul class="pagination pagination-rounded mb-0">
															<b-pagination
																@input="onInvitationsListPageClicked"
																v-model="currentPage"
																:total-rows="rows"
																:per-page="perPage">
															</b-pagination>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</b-tab>
								</b-tabs>
							</div>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>
		<div class="row mt-4">
			<div class="col-sm-6">
			</div>
			<!-- end col -->
			<div class="col-sm-6">
				<div class="text-end">
					<b-button variant="light" class="w-md mb-3" @click="onCancelCreateOrEditCampaignClicked">{{$t('common.cancel')}}</b-button>
					<b-button variant="primary" class="w-md ms-3 mb-3" @click="onConfirmCreateOrEditCampaignClicked">{{$t('common.accept')}}</b-button>
				</div>
			</div>
			<!-- end col -->
		</div>
	</Layout>
</template>

<style scoped>

.edit-picture-button {
	height: 1.5rem !important;
	width: 1.5rem !important;
	top: -5px;
	position: absolute;
	color:#adb5bd
}

.icon-color-grey {
	color:#adb5bd
}

</style>