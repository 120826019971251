<script>
/**
 * Products List component
 */

import CustomProperties from "./custom-product-properties";
import {
  campaignMethods,
  projectMethods
} from "@/state/helpers";

export default {
  components: {
    CustomProperties
  },
  data() {
    return {
      showModalCustomProperties : false,
      showModalStockInformation:false,
      customFields:{
        images:[]
      },
      selectedProduct:null,
      searchSku:'',
      selectedItem : {},
      itemImagesModal:false

    };
  },
  methods: {
    ...campaignMethods,
    ...projectMethods,

    onItemImagesClicked(item){
        this.selectedItem = item.data;
        this.itemImagesModal = true;
    },

    onRemoveProductClicked(product){
      this.$emit('removeProduct',product)
    },

    onCustomizeProductClicked(product) {
      this.selectedProduct = product;
      this.showModalCustomProperties = true;
    },
    getProductStock(product) {
      if(product && product.configurable_attributes && product.configurable_attributes.options && product.configurable_attributes.options.length >0) {

          let total = 0;
          product.configurable_attributes.options.forEach(p => {
            total+= p.stock_qty && p.stock_qty!='' ? parseInt(p.stock_qty) : 0 
          });

        return total;
      }

      return 0;
    },

    handleUpateProduct(mustUpdateProduct) {
      if(mustUpdateProduct){
        if(!this.selectedProduct.data.imgs)
        this.selectedProduct.data.imgs = [];

        this.selectedProduct.data.imgs = [...this.customFields.images, ...this.selectedProduct.data.imgs]
        let loader = this.$loading.show();

       this.updateItem(this.selectedProduct).then(()=>{
          this.$notify({ type: 'success', text: this.$t("campaigns.new_campaign_product_customize_success"),title:  this.$t("campaigns.new_campaign_product_customize") });
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("campaigns.new_campaign_product_customize_error"),title:  this.$t("campaigns.new_campaign_product_customize") });		
        }).finally(()=>{
          loader.hide();
          this.showModalCustomProperties = false;
          this.selectedProduct = null;
        })
      }else{
        this.showModalCustomProperties = false;
        this.selectedProduct = null;
      }
    },

    onStockClicked(product){
      this.selectedProduct = product;
      this.showModalStockInformation = true;
    }
    
  },

  mounted() {
    
  },
  computed: {
    onSearchSku() {
      return this.products.filter(item => {
         return item.data?.sku?.toLowerCase().indexOf(this.searchSku.toLowerCase()) > -1
      })
    }
  },
  props: {
    updating: {
      type: Boolean,
    },
    products: {
      type: Array,
    },
    showActions: {
      type: Boolean,
    },
    showSelect: {
      type: Boolean,
    },
    showStock: {
      type: Boolean,
    },
  },
  emist: ["removeProduct"]
};
</script>

<template>
    
    <div class="table-responsive mb-0">
      <table class="table align-middle table-nowrap">
        <thead class="table-light">
          <tr>
            <th style="width: 20px" v-if="showSelect">
              <div class="form-check font-size-16 align-middle">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="transactionCheck01"
                />
                <label
                  class="form-check-label"
                  for="transactionCheck01">
                </label>
              </div>
            </th>
            <th class="align-middle">
              Sku
              <br>
              <input placeholder="Buscar sku" class="form-control w-20" type="text" v-model="searchSku" style="width: 200px" >
            </th>
            <th class="align-middle">{{$t('campaigns.new_campaign_products_name')}}</th>
            <th class="align-middle" v-if="showStock">Stock</th>
            <th class="align-middle" scope="col">{{$t('items.list_item_images')}}</th>
            <th  class="align-middle" scope="col">{{$t('items.list_item_price')}}</th>
            <th class="align-middle" scope="col">{{$t('items.list_item_status')}}</th>
            <th class="align-middle" v-if="showActions">{{$t('common.actions')}}</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="data in onSearchSku" :key="data._id">
                <td v-if="showSelect">
                    <div class="form-check font-size-16">
                        <input
                        class="form-check-input"
                        type="checkbox"
                        :id="`transactionCheck${data.index}`"
                        />
                        <label
                        class="form-check-label"
                        :for="`transactionCheck${data.index}`"
                        ></label>
                    </div>
                </td>
                <td>{{ data.data.sku }}</td>
                <td>{{ data.data.name }}</td>
                <td v-if="showStock" v-on:click="onStockClicked(data)">{{ getProductStock(data) }} 
                  <i class="bx bx-show-alt"></i>
                </td>
                
                <td><i class= 'bx bx-show interact' v-on:click="onItemImagesClicked(data)"/></td>
                <td>$ {{data.data.price}}</td>
                <td>
                    <span class="badge font-size-10"
                        :class="{
                        'bg-success': `${data.data.active}` === true || `${data.data.active}` == 1,
                        'bg-warning': `${data.data.active}` === false || `${data.data.active}` == 0,
                    }">{{ data.data.active || data.data.active == 1 ? 'Activo' : 'Inactivo' }}</span>
                </td>
                <td v-if="showActions">
                    <b-dropdown
                        class="card-drop"
                        variant="white"
                        right
                        toggle-class="p-0"
                        menu-class="dropdown-menu-end"
                        
                    >
                        <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>

                        <b-dropdown-item  @click="onCustomizeProductClicked(data)">
                        <i class="fas fa-edit text-success me-1"></i>
                        {{$t('common.customize')}}
                        </b-dropdown-item>

                        <b-dropdown-item  @click="onRemoveProductClicked(data)">
                        <i class="fas fa-trash-alt text-danger me-1"></i>
                        {{$t('common.delete')}}
                        </b-dropdown-item>
                    </b-dropdown>
                </td>
            </tr>
        </tbody>
    </table>
    <p class="fw-bold">{{ $t('campaigns.edit_campaign_products_total').replace('{0}',this.products.length)}}</p> 
    <b-modal
      v-model="showModalCustomProperties"
      :title= "$t('campaigns.new_campaign_product_customize')"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer>
      <CustomProperties :customFields="customFields" @updateProduct="handleUpateProduct"/>
    </b-modal>
    
    <b-modal v-model="itemImagesModal" id="modal-center" hide-footer :title="selectedItem?.name" title-class="font-18">
        <b-carousel style="text-shadow: 0px 0px 2px #000" controls indicators fade>
          <b-carousel-slide :img-src="img.url" v-for="img in selectedItem.imgs" :key="img.url"></b-carousel-slide>
        </b-carousel>
    </b-modal>

    <b-modal
      v-model="showModalStockInformation"
      :title= "selectedProduct ? selectedProduct.data.name : ''"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer>
      <table class="table align-middle table-nowrap">
        <thead class="table-light">
          <tr>
            <th class="align-middle">{{selectedProduct && selectedProduct.configurable_attributes && selectedProduct.configurable_attributes.options && selectedProduct.configurable_attributes.options.length > 0 ? selectedProduct.configurable_attributes.label  : 'Talle'}}</th>
            <th class="align-middle">Stock</th>
          </tr>
        </thead>
        <tbody v-if="selectedProduct && selectedProduct.configurable_attributes && selectedProduct.configurable_attributes.options">
          <tr v-for="data in selectedProduct.configurable_attributes.options" :key="data.id" >
            <td>{{ data.option_label }}</td>
            <td>{{ data.stock_qty }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
</div>
    <!-- end table -->
</template>

<style scoped>
.interact{
    cursor: pointer;
}
</style>